import axios from 'axios';

const axiosInstance = axios.create({
    // baseURL: 'http://82.156.174.212:8005/api/', // 统一配置基础 URL
    // baseURL: 'http://47.92.156.237:8007/api/', // 统一配置基础 URL
    // baseURL: 'https://my.zuhaoguanjia.com/api/', // 统一配置基础 URL
    // baseURL: 'https://v2.zuhaoguanjia.com/api/', // 统一配置基础 URL
    baseURL: process.env.VUE_APP_API_BASE_URL,

    withCredentials: true,
    responseType: 'blob' // 需要设置响应类型为blob，以便处理图片文件
});

// 请求拦截器
axiosInstance.interceptors.request.use(
    config => {
        const captchaUuid = localStorage.getItem('captchaUuid')
        config.headers['x-captcha-uuid'] = `${captchaUuid}`;
        
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
    response => {

        const captchaUuid = response.headers['x-captcha-uuid']
        console.log(897866, captchaUuid, response, response.headers)
        if (captchaUuid) {
            localStorage.setItem('captchaUuid', captchaUuid);
        }
        // 如果包含authorization
        const newToken = response.headers['Authorization'];
        if (newToken) {
            localStorage.setItem('access_token', newToken);
        }
        return response;
    },
    error => {
        return Promise.reject(error);
    }
);

export default axiosInstance;