<template>
  <div :class="$style.wrapOut">
    <!-- <div :class="$style.breadcrumb">
      <a-breadcrumb separator=">" :class="$style.breadcrumbWrap">
        <a-breadcrumb-item :class="$style.breadcrumbItem" v-for="cur in gameData?.categories">{{cur || '-'}}</a-breadcrumb-item>
      </a-breadcrumb>
    </div> -->
    <div :class="$style.wrap">
      <div :class="$style.gameInfo">
        <div :class="$style.infoLeft">
          <a-carousel
            arrows
            dots-class="slick-dots slick-thumb"
            autoplay
            :autoplaySpeed="3000"
          >
            <template #customPaging="props">
              <a>
                <img :src="getFullImageUrl(`media/${gameData?.lunbo?.[props?.i]}`)" />
              </a>
            </template>
            <div v-for="item in gameData?.lunbo" :key="index" :class="$style.cardGrid">
              <img :class="$style.cardGridImg" :src="getFullImageUrl(`media/${item}`)" />
            </div>
          </a-carousel>
          <div :class="$style.downloadWrap" @click="onDownloadTool">
            <DownloadOutlined :class="$style.downloadIcon" />
            <span>下载存档工具</span>
          </div>
          <div :class="$style.huodongWrap">分享赚10%-70%</div>
        </div>
        <div :class="$style.infoRight">
          <div :class="$style.rightTitle">{{gameData?.gname || '-'}}</div>
          <div :class="$style.rightEtitle">
            <div :class="$style.eTitle">{{gameData?.ename || '-'}}</div>
            <div :class="$style.collect" @click="onClickCollect">{{isCollect ? '取消收藏' : '收藏'}}</div>
          </div>
          <div :class="$style.rightSeries">
            <div :class="$style.series">
              <div :class="$style.seriesTitle">游戏分类</div>
              <div :class="$style.seriesCon">{{gameData?.categories?.join(' ') || '-'}}</div>
            </div>
          </div>
          <div :class="$style.rightSeries">
            <div :class="$style.series">
              <div :class="$style.seriesTitle">游戏平台</div>
              <div :class="$style.seriesCon">{{gameData?.pingtai || '-'}}</div>
            </div>
            <div :class="$style.series">
              <div :class="$style.seriesTitle">游戏等级</div>
              <div :class="$style.seriesCon">{{gameData?.level || '-'}}</div>
            </div>
          </div>
          <div :class="$style.rightVip">
            <div :class="$style.rightVipImg">会 员</div>
            <div :class="$style.rightVipText">可免费畅玩全站3000+款游戏，价值10万+元。</div>
            <div :class="$style.rightVipBtn"  @click="onClickVip">开通会员</div>
          </div>
          <!-- <div :class="$style.rightDir"></div> -->
          <div :class="$style.rightSkuWrap">
            <div v-for="item in skuData" :class="[$style.rightSku, curSku?.sku_name === item?.sku_name && $style.activeSku]" @click="setCurSku(item)">
              <div :class="[$style.rightPrice, curSku?.sku_name === item?.sku_name && $style.activePrice]">¥{{item?.show_price_info?.use_dkj_price?.toFixed(2) || '-'}}</div>
              <div :class="$style.rightDePrice">已抵扣：{{item?.show_price_info?.use_dkj_amount?.toFixed(2) || '-'}}</div>
              <div :class="[$style.rightSkuName, curSku?.sku_name === item?.sku_name && $style.acticeSkuName]">{{item?.sku_name || '-'}}</div>
            </div>
            <div v-for="item in vipData" :class="[$style.rightSku, curSku?.vip_sku_value === item?.vip_sku_value && $style.activeSku]" @click="setCurSku(item)">
              <div :class="[$style.rightPrice, curSku?.vip_sku_value === item?.vip_sku_value && $style.activePrice]">¥{{item?.use_dkj_price?.toFixed(2) || '-'}}</div>
              <div :class="$style.rightDePrice">已抵扣：{{item?.use_dkj_amount?.toFixed(2) || '-'}}</div>
              <div :class="[$style.rightSkuName, curSku?.vip_sku_value === item?.vip_sku_value && $style.acticeSkuName]">{{item?.vip_sku_value || '-'}}</div>
            </div>
          </div>
          <div :class="$style.rightText">
            <span :class="$style.rightTexto">永久版</span>
            <span :class="$style.rightTextt">可永久无限次租用此正版游戏Steam帐号，无后续费用。</span>
          </div>
          <div :class="$style.rightBtn">
            <a-button type="primary" :class="$style.rightBtnB" @click="onClickBuy">立即购买</a-button>
            <a-button :class="$style.rightBtnV" @click="onClickVipBuy">会员免费玩</a-button>
          </div>
          <div :class="$style.rightTip">
            <div>温馨提示：</div>
            <div>1、如果支付失败，抵扣金将5分钟左右返回！</div>
            <div>2、不得使用外挂，仅个人使用，不能进行转租借或者假激活等商业盈利行为！</div>
          </div>
        </div>
      </div>
      <!-- 新游推荐 -->
      <div :class="$style.new">
        <GamesReBanner
          :left-icon="newIcon"
          :left-img="leftImg"
          :right-img="rightImg"
          left-title="新游推荐"
          :game-infos="getGameInfos(newGames)"
          @on-click-more="onClickMoreNew"
        />
      </div>
      <div :class="$style.gameDetail">
        <div :class="$style.gameDetailTit">游戏介绍</div>
        <div :class="$style.gameDetailCon" v-html="gameData?.introduction || ''"></div>
      </div>
    </div>
    <BuyPlanModal
      :isOpen="isBuyOpen"
      :curSku="curSku"
      @set-is-open="setIsBuyOpen"
    />
    <SelectAccountModal
      :isOpen="isSelectAOpen"
      :gameId="gameData?.id"
      @set-is-open="setIsSelectAOpen"
      @call-back="selectAOpenBack"
    />
  </div>
</template>

<script setup>
  import { ref, onMounted, watch } from "vue";
  import { useRoute, useRouter } from 'vue-router'
  import axios from "@/axios";
  import { DownloadOutlined } from '@ant-design/icons-vue';
  import { message } from 'ant-design-vue';


  import { getFullImageUrl, openLink } from "@/util/utils.js";
  import GamesReBanner from "@/views/homeView/components/GamesReBanner.vue";
  import BuyPlanModal from '@components/BuyPlanModal.vue';
  import SelectAccountModal from '@components/SelectAccountModal.vue';
  import deLLeft from "@assets/deLLeft.png";
  import deLRight from "@assets/deLRight.png";
  import huiyuan from "@assets/huiyuan.png";
  import newIcon from "@assets/newIcon.png";
  import leftImg from "@assets/leftImg.png";
  import rightImg from "@assets/rightImg.png";

  const route = useRoute();
  const router = useRouter();
  const gameData = ref();
  const skuData = ref();
  const vipData = ref();
  const curSku = ref();
  const newGames = ref([]);
  const isCollect = ref(false); // 是否收藏
  const isBuyOpen = ref(false);
  const isSelectAOpen = ref(false);
  const setIsBuyOpen = (flag) => {
    isBuyOpen.value = flag;
  }
  const setIsSelectAOpen = (flag) => {
    isSelectAOpen.value = flag;
  }
  const selectAOpenBack = () => {
    router.push('/dny/rent_active');
  }

  // 切换游戏时，重制选中的curSku
  watch(async () => {
    if (!route.params?.id) return;
    curSku.value = undefined;
  })

  // 获取游戏详情
  watch(async () => {
    if (!route.params?.id) return;
    const res = await axios.post("web/game_detail/", { pk: route.params?.id}).catch(() => {});
    if (res?.data?.status === 10000) {
      gameData.value = res?.data?.data || {};
      isCollect.value = res?.data?.data?.is_collect || false;
      // gameData.value = {
      //   lunbo: ['/gameimg/1230_gNeNusv.jpg', 'gameimg/header_schinese_p2CGooX.jpg', 'gameimg/header_2_jFJlm9n.jpg', 'gameimg/header_XMLgCeP.jpg', 'gameimg/127_0xcZlmk.jpg', 'gameimg/766.jpg']
      // };
    }
  })

  // 获取sku列表
  watch(async () => {
    if (!route.params?.id) return;
    const res = await axios.post("web/skus/", { game_id: route.params?.id}).catch(() => {});
    if (res?.data?.status === 10000) {
      skuData.value = (res?.data?.data?.items || []).slice(0, 1);
      if (skuData.value.length > 0 && !curSku.value) {
      curSku.value = skuData.value[0];
    }
    }
  })

  // 获取会员列表
  onMounted(async () => {
    const res = await axios.post("web/get_members_buy_plan/", {}).catch(() => {});
    if (res?.data?.status === 10000) {
      vipData.value = (res?.data?.data || []).slice(0, 3);
    }
  });

  // 获取新游推荐
  onMounted(async () => {
    const res = await axios.post("web/games-recommend/", { method: 'new-rec' }).catch(() => {});
    if (res?.data?.status === 10000) {
      newGames.value = res?.data?.data || [];
    }
  });

  const getGameInfos = (infos) => {
    const arr = [];
    const step = Math.ceil(infos?.length / 4);
    for(let i = 0; i < step; i++) {
      arr.push(infos?.slice(i * 4, (i + 1) * 4))
    }
    return arr;
  }

  // 选择购买方案
  const setCurSku = (cur) => {
    curSku.value = cur;
  }

  // 存档软件下载
  const onDownloadTool = () => {
    openLink("");
  };

  // 点击新游推荐更多
  const onClickMoreNew = () => {
    console.log('新游推荐more');
  }

  // 点击购买
  const onClickBuy = () => {
    if (!curSku?.value) {
      message.warning('请选择购买方式');
      return;
    }
    if (curSku?.value?.is_links) {
      if (!curSku?.value?.link_addr) return;
      window.open(curSku?.value?.link_addr);
      return;
    }
    isBuyOpen.value = true;
  }

  // 点击会员免费玩
  const onClickVipBuy = async () => {
    const res = await axios.post("web/rentno/vip_rento/", { game_id: route.params?.id }).catch(() => {});
    if (res?.data?.status === 10000) {
      isSelectAOpen.value = true;
    };
    if (res?.data?.status === 10011) {
      isBuyOpen.value = true;
    };
  }

  // 点击开通会员
  const onClickVip = async () => {
    isBuyOpen.value = true;
  }

  // 收藏 || 取消收藏
  const onClickCollect = async () => {
    if (isCollect.value) {
      const res = await axios.post("web/del_collect_game/", { game_id: route.params?.id }).catch(() => {});
      if (res?.data?.status === 10000) {
        isCollect.value = false;
      }
      return;
    }
    const res = await axios.post("web/collect_game/", { game_id: route.params?.id }).catch(() => {});
    if (res?.data?.status === 10000) {
      isCollect.value = true;
    }
  }

</script>

<style module>
  .breadcrumb {
    padding: 0 230px;
    background-color: #F5F5F5;
    margin-bottom: 20px;
  }
  .breadcrumbItem {
    color: #767676 !important;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
  }
  .wrap {
    padding: 10px 230px;
    transform: scale(0.82);
    transform-origin: 50% 0;
    /* margin-bottom: -500px; */
  }
  .gameInfo {
    display: flex;
  }
  .infoLeft {
    flex-shrink: 0;
    width: 656px;
    margin-right: 30px;
  }
  .cardGridImg {
    width: 100%;
    height: 305px;
    border-radius: 8px;
    margin-bottom: 45px;
  }
  .downloadWrap {
    display: flex;
    align-items: center;
    color: #3149FF;
    margin-top: 26px;
    margin-bottom: 50px;
    cursor: pointer;
  }
  .downloadWrap span {
    font-size: 20px;
  }
  .downloadIcon {
    font-size: 22px;
    margin-right: 20px;
  }
  .huodongWrap {
    margin: 0 auto;
    width: 276px;
    height: 51px;
    background-image: url('../../assets/huodong.png');
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 24px;
    line-height: 51px;
    padding-left: 28px;
    color: #fff;
  }
  .infoRight {
    flex-grow: 1;
    width: 0;
  }
  .rightTitle {
    font-size: 34px;
    /* font-weight: bold; */
    color: #2F2F2F;
    margin-top: 26px;
  }
  .rightEtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 28px;
  }
  .eTitle {
    font-size: 24px;
    color: #8E8E8E;
  }
  .collect {
    flex-shrink: 0;
    font-size: 20px;
    color: #245CD9;
    cursor: pointer;
  }

  .rightSeries {
    margin-top: 36px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .series {
    display: flex;
    align-items: center;
    flex: 1; /* 每个系列占据容器的三分之一 */
    padding: 0 0px; /* 给每个系列增加一点左右内边距 */
  }
  .seriesTitle {
    color: #767676;
    margin-right: 20px;
  }
  .seriesCon {
    color: #000000;
    font-weight: bold;
    white-space: nowrap; /* 防止内容换行 */
  }
  .rightVip {
    margin-top: 36px;
    background-color: rgba(245, 184, 18, .28);
    border-radius: 21px;
    display: flex;
    align-items: center;
    padding-right: 21px;
  }
  .rightVipImg {
    width: 112px;
    height: 42px;
    background-image: url('../../assets/huiyuan.png');
    background-repeat: no-repeat;
    background-size: cover;
    line-height: 42px;
    font-size: 22px;
    color: #FCECBE;
    padding-left: 53px;
    margin-right: 20px;
  }
  .rightVipText {
    font-size: 20px;
    color: #412C0E;
  }
  .rightVipBtn {
    font-size: 20px;
    color: #F57212;
    margin-left: auto;
    cursor: pointer;
  }
  .rightDir {
    margin-top: 30px;
    height: 1px;
    background-color: #E5E5E5;
  }
  .rightSkuWrap {
    width: 100%;
    margin-top: 30px;
    display: flex;
    /* overflow-x: auto; */
  }
  .rightSku {
    flex-shrink: 0;
    width: 180px;
    height: 110px;
    border-radius: 5px;
    border: 1px solid #E2E2E2;
    background-color: #F8F8F8;
    position: relative;
    margin-right: 18px;
    cursor: pointer;
  }
  .rightPrice {
    font-size: 24px;
    color: #171C61;
    text-align: center;
    margin-top: 15px;
  }
  .rightDePrice {
    font-size: 14px;
    color: #C6C6C6;
    text-align: center;
    margin-top: 8px;
  }
  .rightSkuName {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 32px;
    font-size: 16px;
    text-align: center;
    line-height: 32px;
    margin-top: auto;
    color: #272727;
    background-color: #EAEAEA;
  }
  .activeSku {
    background-color: #FFFBF8;
    border: 2px solid #F56912;
  }
  .activePrice {
    color: #FF1D1D;
  }
  .acticeSkuName {
    color: #FF1D1C;
    background-color: #FDE4D3;
  }
  .rightText {
    margin-top: 15px;
    height: 65px;
    line-height: 65px;
    background-color: #FAFAFA;
    font-size: 18px;
  }
  .rightTexto {
    color: #767676;
    margin-left: 23px;
    margin-right: 44px;
  }
  .rightTextt {
    color: #000000;
  }
  .rightBtn {
    margin-top: 15px;
  }
  .rightBtnB {
    font-size: 25px;
    width: 200px;
    height: 58px;
    background-color: rgba(255, 73, 49, 1);
    border-radius: 5px;
    margin-right: 24px;
  }
  .rightBtnB:hover {
    background-color: rgba(255, 73, 49, .7) !important;
  }
  .rightBtnV {
    font-size: 25px;
    width: 200px;
    height: 58px;
    border-radius: 5px;
    border: 1px solid rgba(255, 168, 49, 1);
    color: rgba(255, 144, 49, 1);
  }
  .rightBtnV:hover {
    border: 1px solid rgba(255, 168, 49, .7) !important;
    color: rgba(255, 144, 49, .7) !important;
  }
  .rightTip {
    margin-top: 21px;
    font-size: 16px;
    color: #5A5A5A;
  }
  .rightTip div {
    line-height: 30px;
  }
  .new {
    margin-top: 27px;
  }
  .gameDetail {
    margin-top: 35px;
  }
  .gameDetailTit {
    width: 152px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    font-size: 24px;
    color: #ffffff;
    background-color: #3149FF;
    margin-bottom: 31px;
  }
  .gameDetailCon {
    font-size: 22px;
  }
</style>

<style scoped>
  /* For demo */
  :deep(.slick-dots) {
    position: relative;
    height: auto;
  }
  :deep(.slick-slide img) {
    display: block;
  }
  :deep(.slick-arrow) {
    display: none !important;
  }
  :deep(.slick-thumb) {
    bottom: 0px;
  }
  :deep(.slick-thumb li) {
    width: 259px;
    height: 120px;
  }
  :deep(.slick-thumb li img) {
    width: 100%;
    height: 100%;
    /* filter: grayscale(100%); */
    display: block;
  }
  :deep .slick-thumb li.slick-active img {
    /* filter: grayscale(0%); */
  }
</style>