<template>
  <div class="user-profile">
    <a-tabs :active-key="activeKey" @change="handleTabChange">
      <a-tab-pane key="1" tab="个人信息">
        <UserInfo :key="tabKeys['1']" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="我的钱包">
        <Wallet :key="tabKeys['2']" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="消费明细">
        <ConsumptionDetails :key="tabKeys['3']" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="我的收藏">
        <Favorites :key="tabKeys['4']" />
      </a-tab-pane>
      <a-tab-pane key="5" tab="我的浏览">
        <BrowsingHistory :key="tabKeys['5']" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import UserInfo from './UserInfo.vue';
import Wallet from './Wallet.vue';
import ConsumptionDetails from './ConsumptionDetails.vue';
import Favorites from './Favorites.vue';
import BrowsingHistory from './BrowsingHistory.vue';

export default {
  components: {
    UserInfo,
    Wallet,
    ConsumptionDetails,
    Favorites,
    BrowsingHistory
  },
  data() {
    return {
      activeKey: '1',
      tabKeys: {
        '1': 1,
        '2': 2,
        '3': 3,
        '4': 4,
        '5': 5
      }
    };
  },
  methods: {
    handleTabChange(activeKey) {
      this.activeKey = activeKey;
      // 通过增加 key 值来强制重新渲染组件
      this.tabKeys[activeKey] += 1;
    }
  }
};
</script>

<style scoped>
.user-profile {
  padding: 24px;
  background: #fff;
}
</style>
