<template>
  <div class="product-detail">
    <el-card>
      <h2>{{ product.title }}</h2>
      <p>{{ product.description }}</p>
      <el-image :src="product.image" alt="Product Image" style="width: 100%; max-width: 400px;"/>
      <p>价格: {{ product.price }} 元</p>
    </el-card>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  data() {
    return {
      product: {}
    };
  },
  async created() {
    const productId = this.$route.params.id;
    const response = await axios.get(`userprofile/product/${productId}/`);
    if (response.data.status === 10000) {
      this.product = response.data.data;
    }
  },
  watch: {
    '$route.params.id': {
      handler(newId) {
        this.fetchProductDetails(newId);
      },
      immediate: true
    }
  },
  methods: {
    async fetchProductDetails(id) {
      const response = await axios.get(`userprofile/product/${id}/`);
      if (response.data.status === 10000) {
        this.product = response.data.data;
      }
    }
  }
};
</script>
