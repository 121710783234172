<template>
  <el-list>
    <el-list-item v-for="item in favorites" :key="item.id">
      <el-list-item-meta
        :title="item.title"
        :description="item.description"
      />
      <template #actions>
        <el-button type="primary" @click="viewDetail(item.id)">查看详情</el-button>
      </template>
    </el-list-item>
  </el-list>
</template>

<script>
import axios from '../../axios';

export default {
  data() {
    return {
      favorites: []
    };
  },
  async created() {
    try {
      const response = await axios.get('userprofile/favorites/');
      if (response.data.status === 10000) {
        this.favorites = response.data.data;
      }
    } catch (error) {
      console.error('Error fetching favorites:', error);
    }
  },
  methods: {
    viewDetail(id) {
      this.$router.push(`/product/${id}`);
    }
  }
};
</script>
