<template>
  <div :class="$style.header">
    <div :class="$style.left">
      <div :class="$style.leftL" @click="onDownloadH">存档软件下载</div>
      <img :class="$style.leftC" :src="headerD" alt="" @click="onDownloadH" />
      <div :class="$style.leftR">解决存档备份，存档换好的问题！</div>
    </div>
    <div :class="$style.right">
      <div :class="$style.itemR" @click="onClick">加入赚钱</div>
      <div :class="$style.itemR">我的收藏</div>
      <div :class="$style.itemR">独享账号</div>
      <div :class="$style.divder"></div>
      <div :class="[$style.itemR, $style.money]">钱包：{{ store?.state?.user?.amount_sum || "-" }}元</div>
      <div :class="$style.divder"></div>
      <a-dropdown :trigger="['click']">
        <a @click.prevent :class="[$style.itemR, $style.user]">
          {{ store?.state?.user?.username || "-" }}
          <DownOutlined :class="$style.downIcon" />
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item key="0">
              <a href="">1st menu item</a>
            </a-menu-item>
            <a-menu-item key="1">
              <a href="">2nd menu item</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <div :class="$style.divder"></div>
    </div>
  </div>
</template>
<script setup>
  import { ref } from "vue";
  import headerD from "@assets/headerD.png";
  import { openLink } from "@/util/utils.js";
  import { useStore } from "vuex";
  import { DownOutlined } from '@ant-design/icons-vue';
  import { useRouter } from 'vue-router';

  const store = useStore();
  const router = useRouter();

  // 存档软件下载
  const onDownloadH = () => {
    openLink("");
  };

  const onClick = () => {
    router.push('/');
  }
</script>

<style module>
  .header {
    width: 100%;
    height: 30px;
    background-color: #3149ff;
    padding: 0 230px;
    display: flex;
    justify-content: space-between;
  }
  .left {
    display: flex;
    align-items: center;
  }
  .leftL {
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    padding-right: 13px;
  }
  .leftC {
    width: 20px;
    margin-right: 9px;
    cursor: pointer;
  }
  .leftR {
    font-size: 12px;
    color: #ffe202;
  }
  .right {
    display: flex;
    align-items: center;
  }
  .divder {
    height: 20px;
    width: 0;
    border-right: 1px solid #fff;
  }
  .itemR {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    margin-right: 28px;
    font-size: 14px;
    color: #ffffff;
  }
  .money {
    margin-left: 28px;
  }
  .user {
    margin-left: 28px;
  }
  .downIcon {
    margin-left: 10px;
  }
</style>