<template>
  <div :class="$style.kfWrap">
    <div :class="$style.header">
      <img :class="$style.headerIcon" :src="kfIcon" alt="" />
      <div :class="$style.headerTitle">在线客服</div>
    </div>
    <a :class="$style.remark" :href="kfInfo?.card1_url || ''">{{kfInfo?.card1_remark || '-'}}</a>
    <a :class="$style.remark" :href="kfInfo?.card2_url || ''">{{kfInfo?.card2_remark || '-'}}</a>
    <div :class="$style.qrCodeWrap">
      <img :class="$style.qrCode" :src="getFullImageUrl(kfInfo?.qr_code)" alt="" />
    </div>
    <a-button :class="$style.btn" type="primary" shape="round" @click="onClickContact">
      立即联系
    </a-button>
  </div>
</template>

<script setup>
  import { ref, onMounted } from "vue";
  import axios from "@/axios";
  import { getFullImageUrl } from "@/util/utils.js";
  import kfIcon from "@assets/kfIcon.png";

  const kfInfo = ref();

  onMounted(async () => {
    const res = await axios.post("web/api/support/details").catch(() => {});
    if (res?.data?.status === 10000) {
      kfInfo.value = res?.data?.data || {};
    }
  });

  // 点击立即联系
  const onClickContact = () => {
    if (!kfInfo.value?.contact_url) return;
    window.open(kfInfo.value?.contact_url);
  }
</script>

<style module>
  .kfWrap {
    width: 127px;
    position: fixed;
    right: 30px;
    bottom: 50%;
    transform: translate(0, 50%);
    border-radius: 10px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .12);
    background-color: #fff;
  }
  .header {
    width: 100%;
    background-color: #F5B812;
    text-align: center;
    border-radius: 10px;
    padding: 14px 0;
  }
  .headerIcon {
    display: inline-block;
    width: 33px;
    margin-bottom: 15px;
  }
  .headerTitle {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 18px;
  }
  .remark {
    display: block;
    width: 100%;
    margin: 22px auto;
    text-align: center;
    color: #5C5C5C;
    font-size: 16px;
    text-decoration: none;
  }
  .qrCodeWrap {
    margin-bottom: 26px;
    text-align: center;
  }
  .qrCode {
    display: inline-block;
    width: 100px;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 100px;
    height: 27px;
    font-size: 12px;
    background-color: #3149FF;
    margin-bottom: 23px;
  }
</style>
