import axios from 'axios';
import router from './router'
import { message, Modal } from 'ant-design-vue';
import store from './store';
import i18n from './lang';
console.log(process.env)
const axiosInstance = axios.create({
    // baseURL: 'http://82.156.174.212:8005/api/', // 统一配置基础 URL
    // baseURL: 'http://47.92.156.237:8007/api/', // 统一配置基础 URL
    // baseURL: 'https://my.zuhaoguanjia.com/api/', // 统一配置基础 URL
    // baseURL: 'https://v2.zuhaoguanjia.com/api/', // 统一配置基础 URL
    baseURL: process.env.VUE_APP_API_BASE_URL,

    // 设置携带cookie
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    }
});

axiosInstance.defaults.withCredentials=true; // 向后台发送请求时携带凭证


const noAuthRoutes = ['/login/', '/register/', '/home/', 'userauth/login/', 'api/userauth/login/', 'userauth/get_sms_code/', 'api/userauth/get_sms_code/'];

// 请求拦截器
axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('access_token');
        console.log(token, 9999, config.url);
        if (token && !noAuthRoutes.includes(config.url)) {
            config.headers['Authorization'] = `${token}`;
        };
        const captchaUuid = localStorage.getItem('captchaUuid')
        config.headers['x-captcha-uuid'] = `${captchaUuid}`;
        config.headers['language'] = i18n.global.locale || '';
        config.headers['HTTP_ACCEPT_LANGUAGE'] = i18n.global.locale || '';
        config.headers['Accept-Language'] = i18n.global.locale || '';
        
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
    response => {
        const captchaUuid = response.headers['x-captcha-uuid']
        if (captchaUuid) {
            localStorage.setItem('captchaUuid', captchaUuid);
        }

        // 如果包含authorization
        const newToken = response.headers['Authorization'];
        if (newToken) {
            localStorage.setItem('access_token', newToken);
        }
        const alterType = response?.data?.alter_type;
        // 处理非正常业务状态吗，相应提示
        if (response.data.status != 10000 && response.data.msg) {
            if (alterType === 'message') {
                message.error(response.data.msg);
            } else {
                Modal.info({
                    title: '提示',
                    content: response.data.msg || '未知错误',
                })
            }
            // 抛出错误，方便调用方捕获和处理
            // return Promise.reject(new Error(response.data.msg));
        }
        if (response.data.status == 10000 && response.data.msg) {
            if (alterType === 'message') {
                message.success(response.data.msg);
            } else {
                Modal.info({
                    title: '',
                    content: response.data.msg || '未知错误',
                })
            }
        }
        // 身份验证
        if (response.data.status == 10002) {
            store.commit("SET_ISREALOPEN", true);
        }
        return response;
    },
    error => {
        console.log(error, 88888)
        if (error.response && (error.response.status === 401 || error.response?.data?.status === 10401)) {
            console.log(78979789);
            message.error('鉴权失败, 即将跳转到登录页面');
            // 清除鉴权信息
            localStorage.removeItem('access_token');
            // delete axiosInstance.defaults.headers.common['Authorization'];

            // 跳转到登录页面
            router.push({ path: '/login', query: { redirect: router.currentRoute.fullPath } });
        } else if (error.response.status === 404) {
            message.error('页面路径不存在');
        } 
        return Promise.reject(error);
    }
);

export default axiosInstance;