<template>
  <el-table :data="consumptionDetails" style="width: 100%">
    <el-table-column prop="date" label="日期" width="180"></el-table-column>
    <el-table-column prop="description" label="描述"></el-table-column>
    <el-table-column prop="amount" label="金额" width="180"></el-table-column>
  </el-table>
</template>

<script>
import axios from '../../axios';

export default {
  data() {
    return {
      consumptionDetails: []
    };
  },
  async created() {
    const response = await axios.get('userprofile/consumption_details/');
    if (response.data.status === 10000) {
      this.consumptionDetails = response.data.data;
    }
  }
};
</script>
