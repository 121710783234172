<template>
  <el-form :model="userInfo" label-width="120px">
    <el-form-item label="用户名">
      <el-input v-model="userInfo.username" />
    </el-form-item>
    <el-form-item label="手机号">
      <el-input v-model="userInfo.mobile" />
    </el-form-item>
    <el-form-item label="邮箱">
      <el-input v-model="userInfo.email" />
    </el-form-item>
    <el-form-item label="帐户余额">
      <el-input v-model="userInfo.amount" disabled />
    </el-form-item>
    <el-form-item label="身份证名称">
      <el-input v-model="userInfo.idcar_name" />
    </el-form-item>
    <el-form-item label="身份证号">
      <el-input v-model="userInfo.idcar_num" />
    </el-form-item>
    <el-form-item label="支付宝账号">
      <el-input v-model="userInfo.alipay_account" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="updateUserInfo">保存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import axios from '../../axios';

export default {
  data() {
    return {
      userInfo: {}
    };
  },
  async created() {
    try {
      const response = await axios.get('userprofile/user_info/');
      if (response.data.status === 10000) {
        this.userInfo = response.data.data;
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  },
  methods: {
    async updateUserInfo() {
      try {
        const response = await axios.post('userprofile/update_user_info/', this.userInfo);
        if (response.data.status === 10000) {
          // 更新成功的逻辑
        } else {
          // 处理错误
        }
      } catch (error) {
        console.error('Error updating user info:', error);
      }
    }
  }
};
</script>
