// 身份证号校验
export function checkIdCard(idCardNumber) {
  // 1. 检查长度和格式
  if (!/^\d{17}[\dXx]$/.test(idCardNumber)) {
    return false;
  }
  
  // 2. 检查生日是否合法
  const birthday = idCardNumber.substr(6, 8).replace(/(.{4})(.{2})/, '$1-$2-');
  const d = new Date(birthday);
  if (birthday !== (d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2))) {
    return false;
  }
  
  // 3. 校验码校验
  const coefficientArray = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
  const remainderArray = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']; // 校验码对应值
  const idArray = idCardNumber.split('');
  let sum = 0;
  
  for (let i = 0; i < 17; i++) {
    sum += idArray[i] * coefficientArray[i];
  }
  
  const remainder = sum % 11;
  const checkCode = remainderArray[remainder];
  
  return checkCode === idCardNumber.charAt(17).toUpperCase();
}

/**
 * openLink
 * @param url
 * @param isBlank 是否打开新的页面
 */
export function openLink(url, isBlank = false) {
  const link = document.createElement('a');
  let linkUrl = url;
  link.href = linkUrl;
  if (isBlank) {
    link.target = '_blank';
  }
  link.click();
  link.remove();
}

/**
 * 获取完整图片地址
 * @param {string} path 
 */
export function getFullImageUrl(path) {
  if (!path) return '';
  return `https://www.zuhaoguanjia.com/${path}`;
}

/**
 * 等待
 */
export const waitTime = (time = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};