<template>
  <div>
    <h3>帐户余额</h3>
    <p>{{ balance }} 元</p>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  data() {
    return {
      balance: ''
    };
  },
  async created() {
    const response = await axios.get('userprofile/wallet/');
    if (response.data.status === 10000) {
      this.balance = response.data.data.balance;
    }
  }
};
</script>
