import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'amfe-flexible/index.js';
import i18nMixin from '@/lang/i18nMixin'; 

import i18n from '@/lang'

const app = createApp(App);

app.use(router);
app.use(store);
app.use(Antd);
app.use(ElementPlus);
app.use(i18n);
// 为了 全局能用到t对象，页面中直接{{ $t('footer.address') }} 
// js中import { useI18n } from 'vue-i18n';  const { t } = useI18n(); t('header.cdkExchange')
app.use(i18nMixin);

app.mount('#app');