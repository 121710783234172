<template>
  <a-row type="flex" justify="center" align="middle" style="height: 100vh;">
    <!-- <a-col :span="8"> -->
    <a-col>
      <a-card title="Register">
        <a-form
          :model="formState"
          name="normal_register"
          class="register-form"
          @finish="onFinish"
          @finishFailed="onFinishFailed"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <!-- Phone Number -->
          <a-form-item
            label="手机号"
            name="phone"
            :rules="[{ required: true, message: '请输入手机号!' }]"
          >
            <a-input v-model:value="formState.phone">
              <template #prefix>
                <UserOutlined class="site-form-item-icon" />
              </template>
            </a-input>
          </a-form-item>

          <!-- Password -->
          <a-form-item
            label="密码"
            name="password"
            :rules="[{ required: true, message: '请输入用户密码!' }]"
          >
            <a-input-password v-model:value="formState.password">
              <template #prefix>
                <LockOutlined class="site-form-item-icon" />
              </template>
            </a-input-password>
          </a-form-item>

          <!-- Confirm Password -->
          <a-form-item
            label="确认密码"
            name="confirmPassword"
            :rules="[{ required: true, message: '请确认密码!' }]"
          >
            <a-input-password v-model:value="formState.confirmPassword">
              <template #prefix>
                <LockOutlined class="site-form-item-icon" />
              </template>
            </a-input-password>
          </a-form-item>

          <!-- Invite Code -->
          <a-form-item
            label="邀请码(可不填)"
            name="inviteCode"
          >
            <a-input v-model:value="formState.inviteCode">
              <template #prefix>
                <UserOutlined class="site-form-item-icon" />
              </template>
            </a-input>
          </a-form-item>

          <!-- Image Captcha -->
          <a-form-item
            label="图片验证码"
            name="captcha"
            :rules="[{ required: true, message: '请输入图片验证码!' }]"
          >
            <a-input v-model:value="formState.captcha">
              <template #prefix>
                <UserOutlined class="site-form-item-icon" />
              </template>
            </a-input>
            <img :src="captchaUrl" @click="refreshCaptcha" class="captcha-img" />
          </a-form-item>

          <!-- SMS Code -->
          <a-form-item
            label="短信验证码"
            name="smsCode"
            :rules="[{ required: true, message: '请输入短信验证码!' }]"
          >
            <a-input v-model:value="formState.smsCode">
              <template #prefix>
                <UserOutlined class="site-form-item-icon" />
              </template>
            </a-input>
            <a-button @click="getSmsCode" :disabled="smsButtonDisabled">{{ smsButtonText }}</a-button>
          </a-form-item>

          <!-- Agree Terms -->
          <a-form-item>
            <a-checkbox v-model:checked="formState.agreeTerms">同意相关条款 《用户协议》</a-checkbox>
          </a-form-item>

          <!-- Submit Button -->
          <a-form-item>
            <a-button :disabled="disabled" type="primary" html-type="submit" class="register-form-button">
              Register
            </a-button>
            <a href="/login">已有账号 现在去登录</a>
          </a-form-item>
        </a-form>
      </a-card>
    </a-col>
  </a-row>
</template>

<script setup>
import { reactive, computed, ref, onMounted } from 'vue';
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
import axios from '../axios';
import { useRouter } from 'vue-router';
import { message } from 'ant-design-vue';


const formState = reactive({
  phone: '',
  password: '',
  confirmPassword: '',
  inviteCode: '',
  captcha: '',
  smsCode: '',
  agreeTerms: false,
});

const router = useRouter();
const smsButtonDisabled = ref(false);
const smsButtonText = ref('获取验证码');

import axios_blob from '../axios_blob';
// 定义captchaUrl为响应式引用
const captchaUrl = ref('');


const refreshCaptcha = async () => {
  try {
    const response = await axios_blob.get(`userauth/get_image_captcha/?source=reg&time=${Date.now()}`);
    // 创建一个URL对象表示图片的URL
    captchaUrl.value = URL.createObjectURL(response.data);
  } catch (error) {
    console.error('获取验证码失败:', error);
  }
};
// 页面加载时获取验证码
// onMounted(refreshCaptcha);

onMounted(() => {
  refreshCaptcha();

  // 从本地存储中读取邀请码并预填到表单中
  const storedInviteCode = localStorage.getItem('user_invitation_code');
  if (storedInviteCode) {
    formState.inviteCode = storedInviteCode;
  }
});

// Function to get SMS code
const getSmsCode = async () => {
  if (!formState.captcha || !formState.phone) {
    message.error('请先输入图片验证码或手机号');
    return;
  }
  try {
    const response = await axios.post('userauth/get_sms_code/', {
      phone: formState.phone,
      source: 'reg',
      captcha: formState.captcha,
    });
    if (response.data.status == 10000) {
      smsButtonDisabled.value = true;
      smsButtonText.value = '120秒后重新获取';
      let countdown = 120;
      const interval = setInterval(() => {
        countdown -= 1;
        smsButtonText.value = `${countdown}秒后重新获取`;
        if (countdown <= 0) {
          clearInterval(interval);
          smsButtonDisabled.value = false;
          smsButtonText.value = '获取验证码';
        }
      }, 1000);
    } else {
      refreshCaptcha();
    }
  } catch (error) {
    console.error(error);
    refreshCaptcha();
  }
};

const onFinish = async () => {
  try {
    if (formState.password !== formState.confirmPassword) {
      message.error('密码和确认密码不一致');
      return;
    }

    if (!formState.agreeTerms) {
      message.error('请同意相关条款《用户协议》');
      return;
    }

    const response = await axios.post('userauth/register/phone/', formState);
    if (response.data.status !== 10000) {
      //refreshCaptcha();
      return;
    }
    
    router.push('/login');
  } catch (error) {
    console.error(error);
    refreshCaptcha();
  }
};

const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};

const disabled = computed(() => {
  return !(
    formState.phone &&
    formState.password &&
    formState.confirmPassword &&
    formState.captcha &&
    formState.smsCode &&
    formState.agreeTerms
  );
});
</script>

<style scoped>
a-card {
  margin-top: 50px;
}
#components-form-demo-normal-register .register-form {
  max-width: 300px;
}
#components-form-demo-normal-register .register-form-button {
  width: 100%;
}
.captcha-img {
  cursor: pointer;
  margin-top: 10px;
}
</style>
