<template>
  <div>
    <div :class="$style.wrap">
      <div :class="$style.content">
        <div :class="$style.contentLeft">
          <div :class="$style.contentLeftTit">我的游戏库</div>
          <div :class="$style.contentLeftCon">
            <div v-for="item of myGameData" :class="$style.contentLeftItem">
              <div :class="$style.contentLeftItemN">{{item?.gname || '-'}}</div>
              <div :class="$style.contentLeftItemZ" @click="onRentNumber(item?.id)">
                <div>租号</div>
                <img :class="$style.contentLeftItemZI" :src="rightAIcon" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.conDir"></div>
        <div :class="$style.contentRight">
          <div :class="$style.contentRightInner">
              <div :class="$style.contentRightTip">
              <img :class="$style.contentRightTipI" :src="trumpetIcon" alt="" />
              <div :class="$style.contentRightTipT">招募平台推广员，边聊天边赚钱！</div>
            </div>
            <div :class="$style.contentRightTG">
              <div :class="$style.contentRightTGT">推广租号官家，游戏免费玩，天天都能赚到钱</div>
              <div :class="[$style.contentRightTGC, $style.contentRightTGF]">
                <div>会员提成比例：{{kfInfo?.invitation_info?.radio || '-'}}</div>
                <div :class="$style.contentRightTGB" @click="onPromote">我要提升</div>
              </div>
              <div :class="$style.contentRightTGC">我的邀请码：{{kfInfo?.invitation_info?.code || '-'}}</div>
              <div :class="[$style.contentRightTGC, $style.contentRightTGF]">
                <div style="display: flex; align-items: center">
                  <div style="flex-shrink: 0;">我的推广链接：</div>
                  <div :class="$style.contentRightTGCH">{{kfInfo?.invitation_info?.url || '-'}}</div>
                </div>
                <div :class="$style.contentRightTGB" @click="onCopyTg(kfInfo?.invitation_info?.url || undefined)">复制链接</div>
              </div>
              <div :class="$style.contentRightTGC">我的联系方式：{{kfInfo?.invitation_info?.wechat_code || '-'}}</div>
            </div>
            <div :class="$style.rightVip">
              <div :class="$style.rightVipImg">会员</div>
              <div :class="$style.rightVipText">可免费畅玩全站3000+款游戏，价值10万+元。</div>
              <div :class="$style.rightVipBtn" @click="onClickVip">开通会员</div>
            </div>
            <div :class="$style.contentIWrap">
              <div :class="$style.contentI">
                <div :class="$style.contentIT">会员</div>
                <div :class="$style.contentIP">原价</div>
                <div :class="$style.contentID">可抵扣</div>
                <div :class="$style.contentIF">最终权益</div>
              </div>
              <div
                v-for="item in vipData"
                :class="$style.contentI"
              >
                <div :class="$style.contentITit">{{item?.vip_sku_value || '-'}}</div>
                <div :class="$style.contentIPrice">¥{{item?.price?.toFixed(2) || '-'}}</div>
                <div :class="$style.contentIDe">¥{{item?.use_dkj_amount?.toFixed(2) || '-'}}</div>
                <div :class="$style.contentIFP">
                  <div :class="$style.contentIFPT">¥{{item?.use_dkj_price?.toFixed(2) || '-'}}</div>
                  <div :class="$style.contentIFPB">立省¥{{item?.use_dkj_amount?.toFixed(2) || '-'}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Recommend />
      <div :class="$style.rentWrap">
        <img :class="$style.rentIcon" :src="listIcon" alt="" />
        <div>租用记录</div>
      </div>
      <a-table
        :columns="columns"
        :row-key="record => record.id"
        :data-source="dataSource?.items"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
      />
    </div>
    <BuyPlanModal
      :isOpen="isBuyOpen"
      @set-is-open="setIsBuyOpen"
    />
  </div>
</template>

<script setup>
  import { ref, onMounted, h, useCssModule, computed, watch } from "vue";
  import { useStore } from "vuex";
  import axios from "@/axios";
  import { message, Modal } from 'ant-design-vue';
  import useClipboard from 'vue-clipboard3';
  import { usePagination } from 'vue-request';

  import Recommend from '@/views/homeView/Recommend.vue';
  import BuyPlanModal from '@components/BuyPlanModal.vue';
  import rightAIcon from "@assets/rightAIcon.png";
  import trumpetIcon from "@assets/trumpetIcon.png";
  import listIcon from "@assets/listIcon.png";

  const columns = [
  {
    title: '游戏名称',
    dataIndex: 'gname',
    // width: '20%',
  },
  {
    title: '级别',
    dataIndex: 'level',
  },
  {
    title: '游戏平台',
    dataIndex: 'pingtai',
  },
  {
    title: '游戏账号',
    dataIndex: 'saccount',
  },
  {
    title: '开始时间',
    dataIndex: 'stime',
  },
  {
    title: '结束时间',
    dataIndex: 'etime',
  },
  {
    title: '是否空闲',
    dataIndex: 'is_kongxian',
    customRender: (text) => {
      return text ? '是' : '否'
    }
  },
];
  // test
  const { toClipboard } = useClipboard();
  const styles = useCssModule();
  const store = useStore();
  const myGameData = ref();
  const kfInfo = ref();
  const isBuyOpen = ref(false);
  const vipData = ref();
  const setIsBuyOpen = (flag) => {
    isBuyOpen.value = flag;
  }
  console.log(store?.state?.user)

  // 获取我的游戏库列表
  onMounted(async () => {
    const res = await axios.post("web/yong_jiu_rent_quan_yi/", { limit: 999 }).catch(() => {});
    if (res?.data?.status === 10000) {
      myGameData.value = res?.data?.data?.items || [];
    }
  });

  onMounted(async () => {
    const res = await axios.post("web/api/support/details").catch(() => {});
    if (res?.data?.status === 10000) {
      kfInfo.value = res?.data?.data || {};
    }
  });

  // 获取会员信息
  onMounted(async () => {
    const res = await axios.post("web/get_members_buy_plan/", {});
    if (res?.data?.status === 10000) {
      vipData.value = (res?.data?.data || []).slice(0, 3);
    }
  });

  // 租号
  const onRentNumber = (gameId) => {
    console.log(gameId)
  }

  // 复制推广链接
  const onCopyTg = async (text) => {
    if (!text) return;
    try {
      await toClipboard(text);
      message.success('复制成功');
    } catch (e) {
      console.error(e)
    }
  }

  const onPromote = () => {
     Modal.info({
      width: 530,
      title: h('div', { class: styles.tsTitle }, '我要提升:'),
      content: h('div', {}, [
        h('p', { class: styles.tsTitle }, '会员提升规则'),
        h('p', { style: { color: '#FA6F01' } }, '招募平台推广员，边玩游戏边赚钱，轻松上手，月入过万。'),
        h('p', { style: { color: '#FA6F01' } }, '指导老师微信：zuhaoguanjia01'),
      ]),
      onOk() {
        console.log('ok');
      },
    });
  }

  // 点击开通会员
  const onClickVip = async () => {
    isBuyOpen.value = true;
  }

  // 获取租用记录
  const queryRentData = (params) => {
    return axios.post('web/history_rent_no/', { limit: params?.limit || 10, page: params?.page || 1 });
  };

  const {
    data: dataSource,
    run,
    loading,
    current,
    pageSize,
    total,
  } = usePagination(queryRentData, {
    formatResult: res => res?.data?.data,
    pagination: {
      currentKey: 'page',
      pageSizeKey: 'limit',
    },
  });

  const pagination = computed(() => ({
    total: total.value,
    current: current.value,
    pageSize: pageSize.value,
  }));

  const handleTableChange = (
    pag,
    filters,
  ) => {
    run({
      limit: pag.pageSize,
      page: pag?.current,
      ...filters,
    });
  };

</script>

<style module>
  .wrap {
    padding: 23px 230px 0;
  }
  .content {
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(103, 103, 103, .12);
    display: flex;
    padding: 28px 0;
    margin-bottom: 20px;
  }
  .contentLeft {
    flex-shrink: 0;
    padding-left: 35px;
  }
  .conDir {
    width: 1px;
    background-color: #E8E8E8;
    margin-left: 26px;
    margin-right: 22px;
  }
  .contentRight {
    flex-grow: 1;
  }
  .contentRightInner {
    width: calc(100% - 20px);
    padding-right: 20px;
  }
  .contentLeftTit {
    font-weight: bold;
    font-size: 24px;
    color: #2E2E2E;
  }
  .contentLeftCon {
    margin-top: 55px;
    overflow-y: auto;
    max-height: 770px;
  }
  .contentLeftItem {
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 28px;
    margin-bottom: 28px;
    border-bottom: 1px solid #EBEBEB;
  }
  .contentLeftItemN {
    font-size: 20px;
    color: #0F0F0F;
  }
  .contentLeftItemZ {
    flex-shrink: 0;
    font-size: 18px;
    color: #FF870F;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .contentLeftItemZI {
    width: 7px;
    margin-left: 13px;
  }
  .contentRightTip {
    display: flex;
    align-items: center;
    margin-bottom: 21px;
  }
  .contentRightTipI {
    width: 25px;
    margin-right: 23px;
  }
  .contentRightTipT {
    font-size: 21px;
    color: #FF3B3B;
  }
  .contentRightTG {
    width: 100%;
    height: 389px;
    background-image: url('../../assets/tgBgc.png');
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 25px;
    color: #fff;
    margin-bottom: 21px;
    padding: 48px 0 0 58px;
  }
  .contentRightTGT {
    font-size: 36px;
    margin-bottom: 30px;
  }
  .contentRightTGC {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 21px;
    height: 42px;
    line-height: 42px;
  }
  .contentRightTGF {
    display: flex;
    align-items: center;
  }
  .contentRightTGB {
    width: 162px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    border-radius: 42px;
    background-color: #fff;
    color: #FF3E3E;
    font-size: 23px;
    margin-left: 74px;
    cursor: pointer;
    user-select: none;
  }
  .contentRightTGCH {
    word-break: break-all;
    max-width: 500px;
  }
  .tsTitle {
    font-size: 20px;
    font-weight: bold;
  }
  .rightVip {
    background-color: rgba(245, 184, 18, .28);
    border-radius: 21px;
    display: flex;
    align-items: center;
    padding-right: 21px;
    margin-bottom: 21px;
  }
  .rightVipImg {
    width: 112px;
    height: 42px;
    background-image: url('../../assets/huiyuan.png');
    background-repeat: no-repeat;
    background-size: cover;
    line-height: 42px;
    font-size: 22px;
    color: #FCECBE;
    padding-left: 53px;
    margin-right: 20px;
  }
  .rightVipText {
    font-size: 20px;
    color: #412C0E;
  }
  .rightVipBtn {
    font-size: 20px;
    color: #F57212;
    margin-left: auto;
    cursor: pointer;
  }
  .contentIWrap {
    display: flex;
    margin-bottom: 40px;
    justify-content: center;
  }
  .contentI {
    border: 1px solid rgba(180, 180, 180, .2);
    width: 270px;
  }
  .contentITit {
    background-color: #FAF4E2;
    color: #1A1A1A;
    font-size: 21px;
    font-weight: bold;
    height: 52px;
    line-height: 52px;
    text-align: center;
  }
  .contentIT {
    background-color: #FAF4E2;
    font-size: 21px;
    color: #1A1A1A;
    font-weight: bold;
    height: 52px;
    line-height: 52px;
    padding-left: 40px;
  }
  .contentIPrice {
    background-color: #FAFAFA;
    color: #646464;
    font-size: 24px;
    height: 76px;
    line-height: 76px;
    text-align: center;
  }
  .contentIP {
    background-color: #FAFAFA;
    color: #1A1A1A;
    font-size: 21px;
    font-weight: bold;
    height: 76px;
    line-height: 76px;
    padding-left: 40px;
  }
  .contentIDe {
    color: #F5B812;
    font-size: 24px;
    height: 52px;
    line-height: 52px;
    text-align: center;
  }
  .contentID {
    color: #1A1A1A;
    font-size: 21px;
    font-weight: bold;
    height: 52px;
    line-height: 52px;
    padding-left: 40px;
  }
  .contentIFP {
    background-color: #FAFAFA;
    padding: 18px 0 15px 0;
  }
  .contentIF {
    background-color: #FAFAFA;
    color: #1A1A1A;
    font-size: 21px;
    font-weight: bold;
    height: 91px;
    line-height: 91px;
    padding-left: 40px;
  }
  .contentIFPT {
    font-size: 29px;
    color: #FF2727;
    text-align: center;
    margin-bottom: 6px;
  }
  .contentIFPB {
    font-size: 16px;
    color: #888888;
    text-align: center;
    text-decoration: line-through;
  }
  .rentWrap {
    display: flex;
    align-items: center;
    font-size: 28px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 32px;
  }
  .rentIcon {
    width: 32px;
    margin-right: 18px;
  }
</style>
