// 选择租赁账户弹窗
<template>
  <el-dialog :model-value="isOpen" @close="onCancel" :title="$t('permanentCard.selectAccountTitle')" width="30%">
    <p :class="$style.title">
      &nbsp;{{ $t('permanentCard.violationWarning') }}
    </p>
    <el-select v-model="selectedAccount" :placeholder="$t('permanentCard.selectAccountPlaceholder')" style="width: 80%">
      <el-option
        v-for="account in accountOptions"
        :key="account.id"
        :label="account.name"
        :value="account.id"
        :disabled="!account.is_kongxian"
      />
    </el-select>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="onCancel">{{ $t('permanentCard.cancel') }}</el-button>
        <el-button type="primary" @click="confirmRent">{{ $t('permanentCard.confirm') }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
  import { ref, onMounted, watch, watchEffect } from 'vue';
  import axios from '@/axios';
  import { useRouter } from 'vue-router'
  import { message } from 'ant-design-vue';
  import { useI18n } from 'vue-i18n';

  const router = useRouter();
  const { t } = useI18n();

  const props = defineProps({
    isOpen: Boolean,
    gameId: String,
    defaultValue: String, // 默认值
  });
  const emit = defineEmits(["setIsOpen", "callBack"]);
  const selectedAccount = ref(undefined);
  const accountOptions = ref([]);

  // 获取账号列表
  watch([() => props?.gameId, () => props?.isOpen], async () => {
    if (!props?.gameId || !props?.isOpen) return;
    const res = await axios.post("web/rentno/vip_rento/", { game_id: props?.gameId }).catch(() => {});
    if (res?.data?.status === 10000) {
      accountOptions.value = res?.data?.data || [];
    }
  })

  // 设置默认值
  watch([() => props?.defaultValue, () => props?.isOpen], async () => {
    if (!props?.defaultValue || !props?.isOpen) return;
    selectedAccount.value = props?.defaultValue;
  })

  // 点击确认
  const confirmRent = async () => {
    if (!selectedAccount.value) {
      message.warning(t('permanentCard.selectAccountWarning'));
      return;
    }
    const response = await axios.post('/web/rentno/vip_chioce_account/', {
      game_id: props?.gameId,
      account: selectedAccount.value
    });
    if (response.data.status === 10000) {
      emit("setIsOpen", false);
      emit("callBack");
    }
  };

  const onCancel = () => {
    selectedAccount.value = undefined;
    emit("setIsOpen", false);
  }
</script>

<style module>
  .title {
    color: red;
    font-weight: bold;
    font-size: 14px;
  }
</style>
