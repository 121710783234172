<template>
  <a-row type="flex" justify="center" align="middle" style="height: 100vh;">
    <a-col>
      <a-card :title="$t('register.title')">
        <a-form :model="formState" name="normal_register" class="register-form" @finish="onFinish"
          @finishFailed="onFinishFailed" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
          <!-- Email -->
          <a-form-item :label="$t('register.email')" name="email" :rules="[{ required: true, message: $t('register.emailRequired') }]">
            <a-input v-model:value="formState.email">
              <template #prefix>
                <UserOutlined class="site-form-item-icon" />
              </template>
            </a-input>
          </a-form-item>

          <!-- Password -->
          <a-form-item :label="$t('register.password')" name="password" :rules="[{ required: true, message: $t('register.passwordRequired') }]">
            <a-input-password v-model:value="formState.password">
              <template #prefix>
                <LockOutlined class="site-form-item-icon" />
              </template>
            </a-input-password>
          </a-form-item>

          <!-- Confirm Password -->
          <a-form-item :label="$t('register.confirmPassword')" name="confirmPassword" :rules="[{ required: true, message: $t('register.confirmPasswordRequired') }]">
            <a-input-password v-model:value="formState.confirmPassword">
              <template #prefix>
                <LockOutlined class="site-form-item-icon" />
              </template>
            </a-input-password>
          </a-form-item>

          <!-- Invite Code -->
          <a-form-item :label="$t('register.inviteCode')" name="inviteCode">
            <a-input v-model:value="formState.inviteCode">
              <template #prefix>
                <UserOutlined class="site-form-item-icon" />
              </template>
            </a-input>
          </a-form-item>

          <!-- Image Captcha -->
          <a-form-item :label="$t('register.captcha')" name="captcha" :rules="[{ required: true, message: $t('register.captchaRequired') }]">
            <a-input v-model:value="formState.captcha">
              <template #prefix>
                <UserOutlined class="site-form-item-icon" />
              </template>
            </a-input>
            <img :src="captchaUrl" @click="refreshCaptcha" class="captcha-img" />
          </a-form-item>

          <!-- SMS Code -->
          <a-form-item :label="$t('register.smsCode')" name="smsCode" :rules="[{ required: true, message: $t('register.smsCodeRequired') }]">
            <a-input v-model:value="formState.smsCode">
              <template #prefix>
                <UserOutlined class="site-form-item-icon" />
              </template>
            </a-input>
            <a-button @click="getSmsCode" :disabled="smsButtonDisabled">{{ smsButtonText }}</a-button>
          </a-form-item>

          <!-- Agree Terms -->
          <a-form-item>
            <a-checkbox v-model:checked="formState.agreeTerms">{{ $t('register.agreeTerms') }}</a-checkbox>
          </a-form-item>

          <!-- Submit Button -->
          <a-form-item>
            <a-button :disabled="disabled" type="primary" html-type="submit" class="register-form-button">
              {{ $t('register.submit') }}
            </a-button>
            <a href="/login/email">{{ $t('register.login') }}</a>
          </a-form-item>
        </a-form>
      </a-card>
    </a-col>
  </a-row>
</template>

<script setup>
import { reactive, computed, ref, onMounted } from 'vue';
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
import axios from '../axios';
import { useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const formState = reactive({
  email: '',
  password: '',
  confirmPassword: '',
  inviteCode: '',
  captcha: '',
  smsCode: '',
  agreeTerms: false,
});

const router = useRouter();
const smsButtonDisabled = ref(false);
const smsButtonText = ref(t('register.smsCodeButton'));

import axios_blob from '../axios_blob';
// 定义captchaUrl为响应式引用
const captchaUrl = ref('');

const refreshCaptcha = async () => {
  try {
    const response = await axios_blob.get(`userauth/get_image_captcha/?source=reg&time=${Date.now()}`);
    // 创建一个URL对象表示图片的URL
    captchaUrl.value = URL.createObjectURL(response.data);
  } catch (error) {
    console.error(t('register.captchaRefreshFailed'), error);
  }
};
// 页面加载时获取验证码
onMounted(refreshCaptcha);

// Function to get SMS code
const getSmsCode = async () => {
  if (!formState.captcha || !formState.email) {
    message.error(t('register.captchaOrEmailMissing'));
    return;
  }
  try {
    const response = await axios.post('userauth/send_email_captcha/', {
      email: formState.email,
      source: 'reg',
      captcha: formState.captcha,
    });
    if (response.data.status == 10000) {
      smsButtonDisabled.value = true;
      let countdown = 120;
      smsButtonText.value = t('register.smsCodeWaiting', { countdown });
      const interval = setInterval(() => {
        countdown -= 1;
        smsButtonText.value = t('register.smsCodeWaiting', { countdown });
        if (countdown <= 0) {
          clearInterval(interval);
          smsButtonDisabled.value = false;
          smsButtonText.value = t('register.smsCodeButton');
        }
      }, 1000);
    } else {
      refreshCaptcha();
    }
  } catch (error) {
    console.error(error);
    refreshCaptcha();
  }
};

const onFinish = async () => {
  try {
    if (formState.password !== formState.confirmPassword) {
      message.error(t('register.passwordMismatch'));
      return;
    }

    if (!formState.agreeTerms) {
      message.error(t('register.agreeTermsRequired'));
      return;
    }

    const response = await axios.post('userauth/register/email/', formState);
    if (response.data.status !== 10000) {
      //refreshCaptcha();
      return;
    }

    router.push('/login/');
  } catch (error) {
    console.error(error);
    refreshCaptcha();
  }
};

const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};

const disabled = computed(() => {
  return !(
    formState.email &&
    formState.password &&
    formState.confirmPassword &&
    formState.captcha &&
    formState.smsCode &&
    formState.agreeTerms
  );
});
</script>

<style scoped>
a-card {
  margin-top: 50px;
}

#components-form-demo-normal-register .register-form {
  max-width: 300px;
}

#components-form-demo-normal-register .register-form-button {
  width: 100%;
}

.captcha-img {
  cursor: pointer;
  margin-top: 10px;
}
</style>
