<template>
  <el-list>
    <el-list-item v-for="item in browsingHistory" :key="item.id">
      <el-list-item-meta
        :title="item.title"
        :description="item.description"
      />
      <template #actions>
        <el-button type="primary" @click="viewDetail(item.id)">查看详情</el-button>
      </template>
    </el-list-item>
  </el-list>
</template>

<script>
import axios from '../../axios';

export default {
  data() {
    return {
      browsingHistory: []
    };
  },
  async created() {
    const response = await axios.get('userprofile/browsing_history/');
    if (response.data.status === 10000) {
      this.browsingHistory = response.data.data;
    }
  },
  methods: {
    viewDetail(id) {
      this.$router.push(`/product/${id}`);
    }
  }
};
</script>
